import React, { useState, useEffect, useRef } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import DictaphonePresentation from "../../components/Dictaphone/DictaphonePresentation"
import DoubleOptionPopUp from "../../components/PopUps/DoubleOptionPopUp"
import { v4 as uuid } from "uuid"
import WebcamStream from "../../components/WebcamStream/WebcamStream"
import AudioVisualizer from "../../components/AudioVisualizer/AudioVisualizer"
import Timer from "../../components/Timer/Timer"
import { Auth, API } from "aws-amplify"
import { helix } from "ldrs"
import "./PresentationPage.css"
import { observer } from "mobx-react-lite"
import { useStores } from "../../models/root-store"

helix.register()

const PresentationPage = ({ }) => {
  const { userStore, currentRoleplayStore } = useStores()
  const location = useLocation()
  const [name, setName] = useState("")
  const [messages, setMessages] = useState([])
  const [duration, setDuration] = useState(null)
  const [recordWebcam, setRecordWebcam] = useState(true)
  const [enterConvoDetails, setEnterConvoDetails] = useState(false)
  const [conversationName, setConversationName] = useState("")
  const [videoID, setVideoID] = useState(null)
  const [audioID, setAudioId] = useState(null)
  const navigate = useNavigate()
  const videoForm = useRef(null)
  const audioForm = useRef(null)
  const [showEndConvoMessage, setShowEndConvoMessage] = useState(false)
  const [endSession, setEndSession] = useState(false)
  const [startTimer, setStartTimer] = useState(false)
  const [convoDoneScreen, setConvoDoneScreen] = useState(false)
  const [hangUpSignaled, setHangUpSignaled] = useState(false)
  const botStream = useRef(null)
  const screenShareStream = useRef(null)

  const BotStyle = {
    width: "90%",
    height: "45%",
    borderRadius: "5px",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    outline: name === "bot" ? "solid 4px #3045ff" : "none",
    marginRight: "min(5%)",
  }

  const UserStyle = {
    width: "90%",
    height: "45%",
    borderRadius: "5px",
    backgroundImage: "url('../Chat/ChatPageIcons/rectangle882.png')",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    outline: name === "user" ? "solid 4px #3045ff" : "none",
  }
  const sendTranscriptToDB = async () => {
    if (messages.length < 1) {
      alert("First start the conversation in order to save it!")
    } else if ((conversationName.trim().length > 1 && conversationName.trim().length < 25) || location.state) {
      setConvoDoneScreen(true)
      clearConvoInfo()
      const conversation_id = uuid()

      let activity_id = null
      if (location.state) {
        activity_id = location.state.activity_id
      }


      const signedUrlVideoUpload = await API.get("ZenoApp", "/sendVideo", {
        headers: { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` },
        queryStringParameters: {
          user_id: userStore.user.userId,
          videoID: videoID,
          conversation_id: conversation_id,
        },
      })

      await fetch(signedUrlVideoUpload, {
        method: "PUT",
        headers: { "Content-Type": "video/webm" },
        body: videoForm.current,
      })

      await API.post("ZenoApp", "/sendPresentation", {
        headers: { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` },
        body: {
          conversation_id: conversation_id,
          convoTime: duration,
          conversationName: conversationName,
          user_id: userStore.user.userId,
          email: userStore.user.userEmail,
          name: userStore.user.name,
          video_id: videoID,
          audio_id: audioID,
          activity_id: activity_id,
          manager_id: userStore.user.managerId,
          rubric_id: location.state.rubric_id,
        },
      })
      navigate("/assignmentsPage")
    } else {
      alert("Enter Valid Recording Title To Save!")
    }
  }

  const addMessage = message => {
    setMessages(messages => [...messages, message])
  }

  const setSpeaker = speakerType => {
    setName(speakerType)
  }

  const StartTime = bool => {
    setStartTimer(bool)
  }

  const appendBotStream = stream => {
    botStream.current = stream
  }
  const appendScreenShareStream = stream => {
    let video = screenShareStream.current
    video.srcObject = stream
    video.play()
  }

  const signalHangup = () => {
    setHangUpSignaled(true)
    setRecordStatus(false)
  }

  const setRecordStatus = bool => {
    setRecordWebcam(bool)
    setEnterConvoDetails(true)
    setEndSession(true)
    setStartTimer(false)
  }

  const getVideoId = id => {
    setVideoID(id)
  }

  const getVideoForm = form_data => {
    videoForm.current = form_data
  }

  const getAudioForm = form_data => {
    audioForm.current = form_data
  }

  const getAudioId = id => {
    setAudioId(id)
  }

  const handleConvoNameChange = event => {
    setConversationName(event.target.value)
  }

  const captureFrame = () => {
    if (screenShareStream.current) {
      const canvas = document.createElement("canvas")
      const video = screenShareStream.current
      canvas.width = video.videoWidth
      canvas.height = video.videoHeight
      const context = canvas.getContext("2d")
      context.drawImage(video, 0, 0, canvas.width, canvas.height)
      // const dataURL = canvas.toDataURL('image/png');
      return new Promise((resolve, reject) => {
        canvas.toBlob(blob => {
          if (blob) {
            resolve(blob)
          } else {
            reject(new Error("Could not convert canvas to Blob"))
          }
        }, "image/png")
      })
    }
  }

  const checkSimulationReady = async () => {
    if (currentRoleplayStore.currentRoleplay.simulationReady === "false") {
      if (userStore.user.type === "manager") {
        navigate("/activityCreationPage")
      } else {
        navigate("/homepage")
      }
    }
  }

  const clearConvoInfo = () => {
    currentRoleplayStore.setField("simulationReady", "false")
  }

  const dontSave = () => {
    clearConvoInfo()
    if (userStore.user.type === "manager") {
      navigate("/homepage")
    } else {
      navigate("/assignmentsPage")
    }
  }

  useEffect(() => {
    checkSimulationReady()
    return () => { }
  }, [])

  const restartConversation = () => {
    window.location.reload()
  }

  const timer = startTimer ? (
    <Timer
      initialMinute={location.state ? parseInt(location.state.timeLimit) : 30}
      initialSeconds={0}
      setRecordStatus={setRecordStatus}></Timer>
  ) : null

  const EndScreen = convoDoneScreen ? (
    <div className="DoneScreen">
      <div className="PareLogoLoadingScreen"></div>
      <p className="DoneMessage">
        Your role play recording is in transit to the cloud for processing. Analysis will be ready shortly
      </p>
      <div className="DoneScreenloadingDiv">
        <l-helix size="45" speed="1.75" color="white"></l-helix>
      </div>
    </div>
  ) : null
  const endConvoWarning = showEndConvoMessage ? (
    <div className="showEndConvoMessage">
      <p>Please start the conversation or wait for bot to stop speaking before ending conversation</p>
    </div>
  ) : null
  const managerTestPopup = enterConvoDetails ? (
    <div className="convoDetailsDivContainer">
      <div className="convoDetails">
        <h1 className="convoNameInputLabel">Nice job testing!</h1>
        <p className="convoNameInputSubLabel">
          Nice job! If you’re happy with your test, exit the roleplay environment, or restart.
        </p>
        <button className="DontSaveConvoButton" onClick={restartConversation}>
          Restart Module
        </button>
        <button className="saveConvoButton" onClick={() => navigate("/managerDashboard")}>
          Exit Module
        </button>
      </div>
    </div>
  ) : null


  const whoopsRestart = () => {
    setEnterConvoDetails(false)
    restartConversation()
  }

  const namingDiv =
    enterConvoDetails && messages.length == 0 ? (
      <DoubleOptionPopUp header={"Whoops!"} body={`Looks like you are trying to save a recording but you haven’t started the conversation so there’s nothing to
      save. Start the conversation to continue with this role play or exit to start from scratch.`} leftButtonFunction={dontSave} leftButtonLabel="Exit role play" rightButtonFunction={whoopsRestart} rightButtonLabel="Start Conversation"></DoubleOptionPopUp>

    ) : enterConvoDetails && location.state ? (
      <div>
        {hangUpSignaled ? (
          <DoubleOptionPopUp header={"The prospect hung up!"} body={"Now just save your recording below so we can process it for analytics, or don't save and restart."} rightButtonFunction={sendTranscriptToDB} rightButtonLabel="Save" leftButtonFunction={dontSave} leftButtonLabel="Don't Save" ></DoubleOptionPopUp>
        ) : (
            <DoubleOptionPopUp header={"Nice job role playing!"} body={`Now just save your recording below so we can process it for analytics, or don't save and restart.`} rightButtonFunction={sendTranscriptToDB} rightButtonLabel="Save" leftButtonFunction={dontSave} leftButtonLabel="Don't Save"></DoubleOptionPopUp>
          )}
      </div>
    ) : enterConvoDetails ? (
      <div>
        {hangUpSignaled ? (
          <DoubleOptionPopUp header={"The prospect hung up!"} body={"Now just save your recording below so we can process it for analytics, or don't save and restart."} rightButtonFunction={sendTranscriptToDB} rightButtonLabel="Save" leftButtonFunction={dontSave} leftButtonLabel="Don't Save" ></DoubleOptionPopUp>
        ) : (
            <DoubleOptionPopUp header={"The prospect hung up!"} body={"Now just save your recording below so we can process it for analytics, or don't save and restart."} rightButtonFunction={sendTranscriptToDB} rightButtonLabel="Save" leftButtonFunction={dontSave} leftButtonLabel="Don't Save" ></DoubleOptionPopUp>
          )}
      </div>
    ) : null

  return (
    <div className="Background">
      <div className="PresentationWindowsContainer">
        <div className="ScreenShareContainer">
          <video
            ref={screenShareStream}
            style={{ width: "80%", height: "80%", position: "relative", left: "10%", top: "10%" }}></video>
        </div>
        <div className="ParticipantWindowsContainer">
          <div style={BotStyle}>
            <AudioVisualizer botRecorder={botStream.current} speaking={name === "bot"}></AudioVisualizer>
          </div>
          <div style={UserStyle}>
            <WebcamStream></WebcamStream>
          </div>
        </div>
      </div>
      {endConvoWarning}
      <div className="nudges">
        <DictaphonePresentation
          updateTranscript={addMessage}
          setSpeaker={setSpeaker}
          appendScreenShareStream={appendScreenShareStream}
          setDuration={setDuration}
          record={recordWebcam}
          getVideoId={getVideoId}
          endSession={endSession}
          StartTime={StartTime}
          getVideoForm={getVideoForm}
          getAudioId={getAudioId}
          getAudioForm={getAudioForm}
          appendBotStream={appendBotStream}
          recordingType={location.state && userStore.user.type !== "manager" ? "module" : "one-off"}
          signalHangup={signalHangup}
          captureFrame={captureFrame}
        />
      </div>
      <div className="timerDiv">{timer}</div>
      {userStore.user.type === "manager" ? managerTestPopup : namingDiv}
      {startTimer ? (
        <div>
          <button className="RestartButton" onClick={restartConversation}>
            <div className="RestartButtonIcon"></div>
            <label className="RestartButtonLabel">Restart</label>
          </button>
          <div className="SaveConvoButton">
            <button className="EndRolePlayButton" onClick={() => setRecordStatus(false)}>
              <div className="EndRolePlayButtonIcon"></div>
              <label className="EndRolePlayButtonLabel">End Simulation</label>
            </button>
          </div>
        </div>
      ) : null}
      {EndScreen}
    </div>
  )
}

export default observer(PresentationPage)
