import React, { useEffect, useState } from "react";
import { API } from 'aws-amplify';
import ScatterPlot from "../../components/Homedash/scatter";
import BarChartComparison from "../../components/Homedash/compBarChart";
import RepVelocityChart from "../../components/Homedash/veloChart"
import MiniGraph from "../../components/Homedash/miniChart";
import Leaderboard from "../../components/Homedash/leaderboard";
import HomePageSkeleton from "../../components/Homedash/skeletons";
import DropdownSelector from "../../components/LearningCenter/dropdown";
import { Breadcrumb, BreadcrumbItem, BreadcrumbList, BreadcrumbPage } from "../../@shadcn_components/ui/breadcrumb"
import { observer } from "mobx-react-lite"
import { useStores } from "../../models/root-store"

const formatToDollar = (value) => new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2
}).format(value);

const HomePage = observer(() => {
  const { userStore } = useStores()
  const email = userStore.user.userEmail
  const managerEmail = userStore.user.managerEmail
  const name = userStore.user.name
  const userType = userStore.user.type
  const isAdminOrManager = userType === "Admin" || userType === "Manager";
  const [isLoading, setIsLoading] = useState(true);
  const [orgSalesforceData, setOrgSalesforceData] = useState([]);
  const [userCompetencyProfileScores, setUserCompetencyProfileScores] = useState({});
  const [averageCompetencyProfileScores, setAverageCompetencyProfileScores] = useState({});
  // track rep velocity index over time 
  const [rviHistory, setRviHistory] = useState([]);
  const [averageRVI, setAverageRVI] = useState();
  // track sum of opportunities won (currently using sum of total opps, needs update in glue)
  const [oppsWonHistory, setOppsWonHistory] = useState([])
  const [userTotalOppsWon, setUserTotalOppsWon] = useState()
  // track average deal size over time
  const [oppSizeHistory, setOppSizeHistory] = useState([])
  const [averageOppSize, setAverageOppSize] = useState()
  // track average win rate over time
  const [winRateHistory, setWinRateHistory] = useState([])
  const [averageWinRate, setAverageWinRate] = useState()
  // track average deal cycle over time 
  const [dealCycleHistory, setDealCycleHistory] = useState([])
  const [averageDealCycle, setAverageDealCycle] = useState()
  // for admins, store and track available manager views
  const [managers, setManagers] = useState([]);
  const [selectedManager, setSelectedManager] = useState(managerEmail);

  const fetchData = async (managerEmail) => {
    try {
      console.log(`Fetching data for ${managerEmail}`)
      const data = await API.get('DataIntegration', '/getHomePageInfo', {
        queryStringParameters: { email: email, managerEmail: managerEmail, userType: userType }
      });

      setOrgSalesforceData(data.orgSalesforceData);
      setUserCompetencyProfileScores(data.compProfile.competencyScores)
      setAverageCompetencyProfileScores(data.teamCompScores)
      setRviHistory(data.userRVIHistory.items)
      setAverageRVI(data.userRVIHistory.aggregatedValue)
      setOppsWonHistory(data.oppsWon.items)
      setUserTotalOppsWon(data.oppsWon.aggregatedValue)
      setOppSizeHistory(data.avgDealSize.items)
      setAverageOppSize(data.avgDealSize.aggregatedValue)
      setWinRateHistory(data.winRate.items)
      setAverageWinRate(data.winRate.aggregatedValue)
      setDealCycleHistory(data.dealCycle.items)
      setAverageDealCycle(data.dealCycle.aggregatedValue)
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    const fetchManagers = async () => {
      if (userType === 'Admin') {
        try {
          const managersData = await API.get('DataIntegration', '/managerEmails', {});
          setManagers(managersData.managerEmails);
          setSelectedManager(managersData.managerEmails[0]);
        } catch (error) {
          console.error('Error fetching managers:', error);
        }
      }
    };

    fetchManagers();
  }, [userType]);

  useEffect(() => {
    if (selectedManager) {
      setIsLoading(true);
      fetchData(selectedManager);
    }
  }, [selectedManager]);

  const handleManagerChange = (event) => {
    const newManagerEmail = event.target.value;
    setSelectedManager(newManagerEmail);
    setIsLoading(true); // Show loading state while fetching new data
    // Re-fetch data with the new managerEmail
    fetchData(newManagerEmail);
  };

  if (isLoading) {
    return (
      <HomePageSkeleton />
    );
  }

  return (
    <div className="w-full h-full p-8">
      {/* Breadcrumb */}
      <div className="mb-8">
        <Breadcrumb>
          <BreadcrumbList>
            <BreadcrumbItem>
              <BreadcrumbPage>Home</BreadcrumbPage>
            </BreadcrumbItem>
          </BreadcrumbList>
        </Breadcrumb>
      </div>

      {/* User Greeting */}
      <div className="sm:flex sm:items-center mb-8">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">{`Hello, ${name} 👋`}</h1>
          <p className="mt-2 text-sm text-gray-700">See what’s happening and where to spend your time</p>
        </div>

        {userType === 'Admin' && (
          <div className="sm:flex-none">
            <DropdownSelector
              label="Select Manager"
              options={managers} // Assuming each manager has an email field
              value={selectedManager}
              onChange={handleManagerChange}
            />
          </div>
        )}
      </div>

      {/* Grid container */}
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">

        {/* Left column (2/3 width) */}
        <div className="lg:col-span-2 space-y-4">
          {/* Scatter Plot */}
          <div className="bg-white p-4 shadow rounded-lg">
            <h2 className="text-lg font-semibold">Productivity Distribution</h2>
            <p className="mb-2 text-sm text-gray-700">Efficiency Index (EI) / Total Activity</p>
            <ScatterPlot data={orgSalesforceData} />
          </div>
          {/* Team Competency Bar Chart */}
          <div className="bg-white p-4 shadow rounded-lg">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-lg font-semibold">Team Competency Tracking</h2>
              <a
                href="/learningCenterPage"
                className="text-blue-600 text-sm font-semibold hover:underline"
              >
                Go to {isAdminOrManager ? "Coaching" : "Learning"} Center →
              </a>
            </div>
            <BarChartComparison
              userCompetencyScores={userCompetencyProfileScores}
              averageCompetencyScores={averageCompetencyProfileScores.ae} // TODO: update for dynamic dropdown
            />
          </div>
        </div>

        {/* Right column (1/3 width) */}
        <div className="lg:col-span-1 space-y-4">
          {/* Team Rev Efficiency Chart */}
          <div className="bg-white p-4 shadow rounded-lg">
            <h2 className="text-lg font-semibold">Team Average Revenue Efficiency</h2>
            <p className="mb-2 text-md text-gray-700">{formatToDollar(averageRVI)}</p>
            <RepVelocityChart
              data={rviHistory}
            />
          </div>

          {/* Mini charts grid */}
          <div className="grid grid-cols-2 gap-4 lg:grid-cols-2 sm:grid-cols-1">
            {/* Closed Opps */}
            <div className="bg-white p-4 shadow rounded-lg">
              <h2 className="text-md font-semibold">Closed Opps</h2>
              <p className="mb-2 text-md text-gray-700">{userTotalOppsWon}</p>
              <MiniGraph data={oppsWonHistory} label={"Weekly Closed"} fieldKey={"totalOpportunitiesOwned"} />
            </div>

            {/* Avg Deal Size */}
            <div className="bg-white p-4 shadow rounded-lg">
              <h2 className="text-md font-semibold">Avg Deal Size</h2>
              <p className="mb-2 text-md text-gray-700">{formatToDollar(averageOppSize)}</p>
              <MiniGraph data={oppSizeHistory} label={"Avg"} fieldKey={"averageOpportunitySize"} />
            </div>

            {/* Win Rate */}
            <div className="bg-white p-4 shadow rounded-lg">
              <h2 className="text-md font-semibold">Win Rate</h2>
              <p className="mb-2 text-md text-gray-700">%{averageWinRate}</p>
              <MiniGraph data={winRateHistory} label={"Win Rate"} fieldKey={"winRate"} />
            </div>

            {/* Deal Cycle */}
            <div className="bg-white p-4 shadow rounded-lg">
              <h2 className="text-md font-semibold">Deal Cycle</h2>
              <p className="mb-2 text-md text-gray-700">{averageDealCycle}</p>
              <MiniGraph data={dealCycleHistory} label={"Time (days)"} fieldKey={"averageSalesCycleLength"} />
            </div>

          </div>


          {/* Leaderboard */}
          <div className="bg-white p-4 shadow rounded-lg">
            <h2 className="text-lg font-semibold">Efficiency Leaderboard</h2>
            <Leaderboard people={orgSalesforceData} />
          </div>
        </div>
      </div>
    </div>
  );
});

export default HomePage;
