import React, { useState, useEffect } from "react"
import { useQuery } from "@tanstack/react-query"
import { Auth, API } from "aws-amplify"
import awsmobile from "../../aws-exports"
import { Avatar, AvatarFallback, AvatarImage } from "../../@shadcn_components/ui/avatar"
import "./LeaderboardEntry.css"

Auth.configure(awsmobile)

const LeaderboardEntry = ({ rank, name, rvi, color, user_id }) => {
  const [profilePicIcon, setProfilePicIcon] = useState(null)

  // const { isPending, error, data } = useQuery({
  //   queryKey: [`ProfilePicture_${user_id}`],
  //   queryFn: async () => {
  //     const ProfilePhotoUrl = await API.get("ZenoApp", "/getProfilePicture", {
  //       headers: { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` },
  //       queryStringParameters: {
  //         user_id: user_id,
  //       },
  //     })
  //     return { ProfilePhotoUrl }
  //   },
  //   staleTime: Infinity,
  //   cacheTime: Infinity,
  // })

  // useEffect(() => {
  //   if (isPending) {
  //   } else {
  //     setProfilePicIcon(data.ProfilePhotoUrl)
  //   }
  // }, [isPending])


  const initials = name.split(" ")[0].toUpperCase().charAt(0) + name.split(" ")[1].toUpperCase().charAt(0)
  return (
    <div className="w-full flex py-2">
      <label className="w-full h-full flex w-1/4 justify-center items-center text-gray-400">{rank}</label>
      <div className="w-full h-full w-1/4 flex justify-center items-center">
        <Avatar>
          <AvatarImage
            src={profilePicIcon}
          />
          <AvatarFallback>{initials}</AvatarFallback>
        </Avatar>
        {/* <div style={teamMemberIconStyle}>
          {profilePicIcon ? <img src={profilePicIcon} className="TopNavProPic"></img> : initials}
        </div> */}
      </div>
      <label className="w-full h-full w-1/4 flex justify-center items-center text-sm ">{name}</label>
      <label className="w-full h-full w-1/4  flex  justify-center items-center text-semi-bold">{Math.round(rvi)}</label>
    </div>
  )
}

export default LeaderboardEntry
