import React, { useState, useRef } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import DictaphoneProto from "../../components/Dictaphone/DictaphoneProto"
import { v4 as uuid } from "uuid"
import WebcamStream from "../../components/WebcamStream/WebcamStream"
import DoubleOptionPopUp from "../../components/PopUps/DoubleOptionPopUp"
import AudioVisualizer from "../../components/AudioVisualizer/AudioVisualizer"
import Timer from "../../components/Timer/Timer"
import { Auth, API } from "aws-amplify"
import { helix } from "ldrs"
import "./ChatPage.css"
import { observer } from "mobx-react-lite"
import { useStores } from "../../models/root-store"

helix.register()

const ChatPage = () => {
  const { userStore, currentRoleplayStore } = useStores()
  const { userId, userEmail, managerId, name, type } = userStore.user || {}
  const location = useLocation()
  const [speaker, setSpeakerType] = useState("")
  const [messages, setMessages] = useState([])
  const [duration, setDuration] = useState(null)
  const [speakerTurnTimes, setSpeakerTurnTimes] = useState([])
  const [recordWebcam, setRecordWebcam] = useState(true)
  const [enterConvoDetails, setEnterConvoDetails] = useState(false)
  const [conversationName, setConversationName] = useState("")
  const [videoID, setVideoID] = useState(null)
  const [audioID, setAudioId] = useState(null)
  const navigate = useNavigate()
  const videoForm = useRef(null)
  const audioForm = useRef(null)
  const [showEndConvoMessage, setShowEndConvoMessage] = useState(false)
  const [endSession, setEndSession] = useState(false)
  const [startTimer, setStartTimer] = useState(false)
  const [convoDoneScreen, setConvoDoneScreen] = useState(false)
  const [hangUpSignaled, setHangUpSignaled] = useState(false)
  const botStream = useRef(null)

  const sendTranscriptToDB = async () => {
    if (messages.length < 1) {
      alert("First start the conversation in order to save it!")
    } else if ((conversationName.trim().length > 1 && conversationName.trim().length < 25) || location.state) {
      setConvoDoneScreen(true)
      clearConvoInfo()
      const conversation_id = uuid()
      let activity_id = null
      if (location.state) {
        activity_id = location.state.activity_id
      }

      const signedUrlVideoUpload = await API.get("ZenoApp", "/sendVideo", {
        headers: { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` },
        queryStringParameters: {
          user_id: userId,
          videoID: videoID,
          conversation_id: conversation_id,
        },
      })

      await fetch(signedUrlVideoUpload, {
        method: "PUT",
        headers: { "Content-Type": "video/webm" },
        body: videoForm.current,
      })

      const sendTranscriptToDBReq = await API.post("ZenoApp", "/sendAudioFile", {
        headers: { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` },
        body: {
          transcript: messages,
          conversation_id: conversation_id,
          convoTime: duration,
          conversationName: conversationName,
          user_id: userId,
          email: userEmail,
          name: name,
          video_id: videoID,
          audio_id: audioID,
          callType: currentRoleplayStore.currentRoleplay.callType,
          activity_id: activity_id,
          manager_id: managerId,
          rubric_id: location.state.rubric_id,
        },
      })
      navigate("/assignmentsPage")
    } else {
      alert("Enter Valid Recording Title To Save!")
    }
  }

  const addMessage = message => {
    setMessages(messages => [...messages, message])
  }

  const addSpeakerTurnTime = turnTime => {
    if ((turnTime.end - turnTime.start) / 1000 > 5) {
    }
    setSpeakerTurnTimes(turnTimes => [...turnTimes, turnTime])
  }

  const setSpeaker = speakerType => {
    setSpeakerType(speakerType)
  }

  const StartTime = bool => {
    setStartTimer(bool)
  }

  const appendBotStream = stream => {
    botStream.current = stream
  }

  const signalHangup = () => {
    setHangUpSignaled(true)
    setRecordStatus(false)
  }

  const setRecordStatus = bool => {
    setRecordWebcam(bool)
    setEnterConvoDetails(true)
    setEndSession(true)
    setStartTimer(false)
  }

  const getVideoId = id => {
    setVideoID(id)
  }

  const getVideoForm = form_data => {
    videoForm.current = form_data
  }

  const getAudioForm = form_data => {
    audioForm.current = form_data
  }

  const getAudioId = id => {
    setAudioId(id)
  }


  const clearConvoInfo = () => {
    currentRoleplayStore.setField("simulationReady", "false")
  }

  const dontSave = () => {
    clearConvoInfo()
    if (type === "Manager") {
      navigate("/homepage")
    } else {
      navigate("/assignmentsPage")
    }
  }

  const restartConversation = () => {
    window.location.reload()
  }

  const timer = startTimer ? (
    <Timer
      initialMinute={location.state ? parseInt(location.state.timeLimit) : 30}
      initialSeconds={0}
      setRecordStatus={setRecordStatus}></Timer>
  ) : null

  const EndScreen = convoDoneScreen ? (
    <div className="DoneScreen">
      <div className="PareLogoLoadingScreen"></div>
      <p className="DoneMessage">
        Your role play recording is in transit to the cloud for processing. Analysis will be ready shortly
      </p>
      <div className="DoneScreenloadingDiv">
        <l-helix size="45" speed="1.75" color="white"></l-helix>
      </div>
    </div>
  ) : null
  const endConvoWarning = showEndConvoMessage ? (
    <div className="showEndConvoMessage">
      <p>Please start the conversation or wait for bot to stop speaking before ending conversation</p>
    </div>
  ) : null
  const managerTestPopup = enterConvoDetails ? (
    <div className="convoDetailsDivContainer">
      <div className="convoDetails">
        <h1 className="convoNameInputLabel">Nice job testing!</h1>
        <p className="convoNameInputSubLabel">
          Nice job! If you’re happy with your test, exit the roleplay environment, or restart.
        </p>
        <button className="DontSaveConvoButton" onClick={restartConversation}>
          Restart Module
        </button>
        <button className="saveConvoButton" onClick={() => navigate("/managerDashboard")}>
          Exit Module
        </button>
      </div>
    </div>
  ) : null

  const whoopsRestart = () => {
    setEnterConvoDetails(false)
    restartConversation()
  }
  const namingDiv =
    enterConvoDetails && messages.length == 0 ? (
      <DoubleOptionPopUp header={"Whoops!"} body={`Looks like you are trying to save a recording but you haven’t started the conversation so there’s nothing to
      save. Start the conversation to continue with this role play or exit to start from scratch.`} leftButtonFunction={dontSave} leftButtonLabel="Exit role play" rightButtonFunction={whoopsRestart} rightButtonLabel="Start Conversation"></DoubleOptionPopUp>

    ) : enterConvoDetails && location.state ? (
      <div>
        {hangUpSignaled ? (
          <DoubleOptionPopUp header={"The prospect hung up!"} body={"Now just save your recording below so we can process it for analytics, or don't save and restart."} rightButtonFunction={sendTranscriptToDB} rightButtonLabel="Save" leftButtonFunction={dontSave} leftButtonLabel="Don't Save" ></DoubleOptionPopUp>
        ) : (
            <DoubleOptionPopUp header={"Nice job role playing!"} body={`Now just save your recording below so we can process it for analytics, or don't save and restart.`} rightButtonFunction={sendTranscriptToDB} rightButtonLabel="Save" leftButtonFunction={dontSave} leftButtonLabel="Don't Save"></DoubleOptionPopUp>
          )}
      </div>
    ) : enterConvoDetails ? (
      <div>
        {hangUpSignaled ? (
          <DoubleOptionPopUp header={"The prospect hung up!"} body={"Now just save your recording below so we can process it for analytics, or don't save and restart."} rightButtonFunction={sendTranscriptToDB} rightButtonLabel="Save" leftButtonFunction={dontSave} leftButtonLabel="Don't Save" ></DoubleOptionPopUp>
        ) : (
            <DoubleOptionPopUp header={"The prospect hung up!"} body={"Now just save your recording below so we can process it for analytics, or don't save and restart."} rightButtonFunction={sendTranscriptToDB} rightButtonLabel="Save" leftButtonFunction={dontSave} leftButtonLabel="Don't Save" ></DoubleOptionPopUp>
          )}
      </div>
    ) : null

  return (
    <div className="xl:pl-0 flex flex-col w-full">
      <div class="w-full flex h-[12%]"></div>
      <div className="flex w-full p-10 space-x-20">
        <div className={`h-full flex-1 rounded-[5px] bg-center bg-no-repeat bg-cover ${speaker === "bot" ? "outline outline-[4px] outline-[#3045ff]" : "outline outline-[4px] outline-[rgb(231,231,231)]"}`}>
          <AudioVisualizer botRecorder={botStream.current} speaking={speaker === "bot"}></AudioVisualizer>
        </div>
        <div className={`h-full flex-1 rounded-[5px] bg-black ${speaker === "user" ? "outline outline-[4px] outline-[#3045ff]" : "outline-none"}`} >
          <WebcamStream></WebcamStream>
        </div>
      </div>
      {endConvoWarning}
      <div className="w-full h-12 px-6">
        <DictaphoneProto
          updateTranscript={addMessage}
          setSpeaker={setSpeaker}
          speaker={speaker}
          setDuration={setDuration}
          addSpeakerTurnTime={addSpeakerTurnTime}
          record={recordWebcam}
          getVideoId={getVideoId}
          getAudioId={getAudioId}
          endSession={endSession}
          StartTime={StartTime}
          getVideoForm={getVideoForm}
          getAudioForm={getAudioForm}
          appendBotStream={appendBotStream}
          recordingType={location.state && type !== "Manager" ? "module" : "one-off"}
          signalHangup={signalHangup}
        />
      </div>
      <div className="w-full h-20 flex px-6">{timer}</div>
      {userStore.user ?.type === "manager" ? managerTestPopup : namingDiv}
      {startTimer ? (
        <div class="flex justify-end px-6">
          <button class="ml-2 px-10 py-2 rounded w-[150px] h-[40px] flex items-center justify-center space-x-2 hover:bg-grey-200" onClick={restartConversation}>
            <div className="RestartButtonIcon"></div>
            <label className="RestartButtonLabel">Restart</label>
          </button>
          <button class="ml-2 px-10 py-2 bg-red-500 rounded w-[240px] h-[40px] flex items-center justify-center space-x-2 hover:bg-red-700" onClick={() => setRecordStatus(false)}>
            <div className="EndRolePlayButtonIcon"></div>
            <label className="text-white">End Simulation</label>
          </button>
        </div>
      ) : null}
      {EndScreen}
    </div>
  )
}

export default observer(ChatPage)
