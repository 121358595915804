import React from 'react';
import { Line } from 'react-chartjs-2';

const MiniGraph = ({ data, fieldKey, label }) => {
    const chartData = {
        labels: data.map(entry => entry.date),  // X-axis: Dates
        datasets: [
            {
                label: label || '',  // Optionally pass a label, or keep it empty
                data: data.map(entry => entry[fieldKey]),  // Y-axis: Values (Dynamic field)
                fill: false,
                borderColor: 'blue',
                borderWidth: 2,  // Slimmer line
                tension: 0.3,  // Smooth line
                pointRadius: 2,  // Smaller points for a compact graph
                pointHoverRadius: 5,  // Slightly larger point on hover for visibility
                pointHoverBorderWidth: 2,  // Thicker border on hover
                pointHoverBackgroundColor: 'red',  // Highlight point on hover
            }
        ]
    };

    const options = {
        scales: {
            x: {
                display: false  // No X-axis (labels or grid)
            },
            y: {
                display: false,  // No Y-axis (labels or grid)
                beginAtZero: false  // Y-axis does not need to start at zero
            }
        },
        plugins: {
            title: {
                display: false  // No title for minimalist design
            },
            legend: {
                display: false  // No legend to reduce clutter
            },
            tooltip: {
                enabled: true,  // Enable tooltips
                callbacks: {
                    label: function(tooltipItem) {
                        return `${label}: ${tooltipItem.raw}`;  // Custom label for tooltips
                    }
                },
                backgroundColor: '#333',  // Tooltip background color
                titleFont: { size: 14 },
                bodyFont: { size: 12 },
                padding: 10,
            },
            hover: {
                mode: 'nearest',  // Hover nearest point
                intersect: false,  // Trigger on closest point rather than intersecting
            },
        },
        maintainAspectRatio: false,  // Don't force aspect ratio
        responsive: true,  // Make sure it resizes properly
        layout: {
            padding: {
                left: 0,
                right: 0,
                top: 5,
                bottom: 5
            }
        },
        elements: {
            line: {
                tension: 0.4  // Slightly smooth line for material feel
            }
        }
    };

    return (
        <div style={{ height: '60px', width: '100%' }}>  {/* Mini graph size */}
            <Line
                data={chartData}
                options={options}
            />
        </div>
    );
};

export default MiniGraph;
