import React from 'react';
import { Line } from 'react-chartjs-2';

const RepVelocityChart = ({ data }) => {
    // Prepare data for Chart.js
    console.log(JSON.stringify(data))
    const chartData = {
        labels: data.map(entry => entry.date),  // X-axis: Dates
        datasets: [
            {
                label: 'Rep Velocity Index',
                data: data.map(entry => entry.repVelocityIndex),  // Y-axis: repVelocityIndex values
                fill: false,
                borderColor: 'blue',  // Material design-like blue line
                borderWidth: 2,  // Slimmer line for material design style
                tension: 0.3,  // Smoother line for aesthetics
                pointRadius: 4,  // Increase point size for clearer data points
                pointHoverRadius: 8  // Slightly larger point on hover
            }
        ]
    };

    const options = {
        scales: {
            x: {
                display: false  // Hide X-axis (no labels or grid lines)
            },
            y: {
                display: false,  // Hide Y-axis (no labels or grid lines)
                beginAtZero: false  // Don't start Y-axis at zero
            }
        },
        plugins: {
            title: {
                display: false  // Remove chart title for a cleaner look
            },
            legend: {
                display: false  // Hide legend to reduce clutter
            },
            tooltip: {
                enabled: true,  // Keep tooltips enabled for point hover
                backgroundColor: '#333',  // Tooltip background for material design style
                titleFont: { size: 14 },
                bodyFont: { size: 12 }
            }
        },
        elements: {
            line: {
                tension: 0.4  // Smooth the line for better visual appeal
            }
        }
    };

    return (
        <div style={{ height: '180px', width: '100%' }}>
            <Line
                data={chartData}
                options={options}
            />
        </div>
    );
};

export default RepVelocityChart;
