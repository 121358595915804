import React, { useMemo, useState, useEffect } from 'react';
import { Scatter } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
import 'chart.js/auto';

// Register Chart.js components and plugins
Chart.register(...registerables);

// Helper function to get initials from a name
const getInitials = (name) => {
  if (!name) return '';
  const [firstName, lastName] = name.split(' ');
  return `${firstName?.[0] || ''}${lastName?.[0] || ''}`.toUpperCase();
};

const ScatterPlot = ({ data }) => {
  const [preloadedImages, setPreloadedImages] = useState({});

  // Preload profile images
  useEffect(() => {
    const loadImages = async () => {
      const images = {};

      for (const rep of data) {
        if (rep.profilePicture) {
          const img = new Image();
          img.src = rep.profilePicture;
          await new Promise((resolve) => {
            img.onload = resolve;
          });
          images[rep.email] = img; // Store the preloaded image by email (unique identifier)
        }
      }

      setPreloadedImages(images);
    };

    loadImages();
  }, [data]);

  // Pre-calculate initials once and calculate min/max for buffer
  const chartData = useMemo(() => {
    if (!data || data.length === 0) {
      return {
        datasets: [{
          label: 'No Data Available',
          data: [],
        }],
      };
    }

    const chartPoints = data.map(rep => ({
      x: rep.activity || 0,
      y: rep.rvi || 0,
      name: rep.name,
      profilePicture: rep.profilePicture,
      initials: getInitials(rep.name),
      email: rep.email, // Store email for image lookup
    }));

    // Calculate min and max values for x and y axes
    const minX = Math.min(...chartPoints.map(point => point.x));
    const maxX = Math.max(...chartPoints.map(point => point.x));
    const minY = Math.min(...chartPoints.map(point => point.y));
    const maxY = Math.max(...chartPoints.map(point => point.y));

    // Add buffer (e.g., 10% of the range) to min and max values
    const xBuffer = (maxX - minX) * 0.1;
    const yBuffer = (maxY - minY) * 0.1;

    return {
      datasets: [{
        label: 'EI / Activity',
        data: chartPoints,
        pointRadius: 20, // Size of the point
        pointStyle: 'circle',
        backgroundColor: 'rgba(0, 0, 0, 0)', // Transparent by default
        borderColor: 'rgba(0, 0, 0, 0)', // Transparent by default
        hoverBackgroundColor: 'rgba(75, 192, 192, 0.8)', // Highlight on hover
        hoverBorderColor: 'rgba(75, 192, 192, 1)', // Highlight border on hover
        hoverBorderWidth: 3, // Thicker border on hover
      }],
      xMin: minX - xBuffer,
      xMax: maxX + xBuffer,
      yMin: minY - yBuffer,
      yMax: maxY + yBuffer,
    };
  }, [data]);

  const options = {
    scales: {
      x: {
        title: {
          display: true,
          text: 'Total Activity',
        },
        min: chartData.xMin, // Apply buffer
        max: chartData.xMax, // Apply buffer
        grid: {
          drawOnChartArea: false, // Remove all x-axis grid lines
          drawTicks: false, // Hide ticks
        },
        ticks: {
          callback: function(value, index, values) {
            return index === Math.floor(values.length / 2) ? value : ''; // Only show the center tick
          },
        },
      },
      y: {
        title: {
          display: true,
          text: 'Efficiency Index',
        },
        min: chartData.yMin, // Apply buffer
        max: chartData.yMax, // Apply buffer
        grid: {
          drawOnChartArea: false, // Remove all y-axis grid lines
          drawTicks: false, // Hide ticks
        },
        ticks: {
          callback: function(value, index, values) {
            return index === Math.floor(values.length / 2) ? value : ''; // Only show the center tick
          },
        },
      },
    },
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      tooltip: {
        enabled: true,
        callbacks: {
          // Custom tooltip content
          label: function(tooltipItem) {
            const point = tooltipItem.raw;
            return `${point.name}: Activity ${point.x}, RVI ${point.y}`;
          }
        },
        backgroundColor: 'rgba(0, 0, 0, 0.7)', // Dark background for tooltips
        titleFont: { size: 16 },
        bodyFont: { size: 14 },
        padding: 10,
        displayColors: false, // Remove the color box
      },
      // Custom plugin to render profile initials or pictures
      profilePicturePlugin: {
        id: 'profilePicturePlugin',
        afterDatasetsDraw: (chart) => {
          const ctx = chart.ctx;
          chart.data.datasets[0].data.forEach((point, index) => {
            const { x, y } = chart.getDatasetMeta(0).data[index].getProps(['x', 'y'], true);
            const img = preloadedImages[point.email]; // Fetch preloaded image by email
            const initials = point.initials;

            ctx.save();
            ctx.beginPath();
            ctx.arc(x, y, 20, 0, 2 * Math.PI); // Circle background
            ctx.fillStyle = '#3045ff'; 
            ctx.fill();

            if (img) {
              ctx.clip();
              ctx.drawImage(img, x - 20, y - 20, 40, 40); // Draw the preloaded image
            } else {
              // Draw initials if no image is available
              ctx.fillStyle = '#fff'; 
              ctx.textAlign = 'center';
              ctx.textBaseline = 'middle';
              ctx.font = '16px Arial';
              ctx.fillText(initials, x, y);
            }

            ctx.restore();
          });

          // Draw horizontal and vertical lines to bisect the chart
          const { chartArea } = chart;
          const midX = (chartArea.left + chartArea.right) / 2;
          const midY = (chartArea.top + chartArea.bottom) / 2;

          ctx.save();
          ctx.strokeStyle = 'blue';
          ctx.setLineDash([5, 5]); // Dotted line

          // Draw vertical line
          ctx.beginPath();
          ctx.moveTo(midX, chartArea.top);
          ctx.lineTo(midX, chartArea.bottom);
          ctx.stroke();

          // Draw horizontal line
          ctx.beginPath();
          ctx.moveTo(chartArea.left, midY);
          ctx.lineTo(chartArea.right, midY);
          ctx.stroke();

          // Draw labels for each quadrant outside the chart
          const quadrantLabels = {
            topLeft: "High Performer",
            topRight: "Star Talent",
            bottomLeft: "Talent Risk",
            bottomRight: "Star Potential"
          };

          ctx.font = "14px Arial";
          ctx.fillStyle = "#374151";
          ctx.textAlign = "center";
          ctx.textBaseline = "middle";

          ctx.fillText(quadrantLabels.topLeft, midX - (chartArea.left + midX) / 2, chartArea.top - 20);
          ctx.fillText(quadrantLabels.topRight, midX + (chartArea.right - midX) / 2, chartArea.top - 20);
          ctx.fillText(quadrantLabels.bottomLeft, (midX - chartArea.left) / 2, chartArea.bottom + 20);
          ctx.fillText(quadrantLabels.bottomRight, midX + (chartArea.right - midX) / 2, chartArea.bottom + 20);

          ctx.restore();
        },
      },
    },
    hover: {
      mode: 'nearest',
      intersect: true,
    },
  };

  return (
    <div style={{ height: '400px', width: '100%' }}>
      <Scatter data={chartData} options={options} plugins={[options.plugins.profilePicturePlugin]} />
    </div>
  );
};

export default ScatterPlot;
