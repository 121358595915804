import React, { useEffect, useState } from "react"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"

import ChatPage from "./pages/ChatPage/ChatPage"
import PresentationPage from "./pages/PresentationPage/PresentationPage"
import ContextPage from "./pages/ContextPage/ContextPage"
import HomePage from "./pages/HomePage/HomePage"
import PricingPage from "./pages/PricingPage/PricingPage"
import ManagerDashboardPage from "./pages/ManagerDashboardPage/ManagerDashboardPage"
import MyTeamPage from "./pages/MyTeamPage/MyTeamPage"
import ManagerDevelopmentPage from "./pages/ManagerDevelopmentPage/ManagerDevelopmentPage"
import ActivityTeamViewPage from "./pages/ActivityTeamViewPage/ActivityTeamViewPage"
import ActivityCreationPage from "./pages/ActivityCreationPage/ActivityCreationPage"
import ModuleAnalysisPage from "./pages/ModuleAnalysisPage/ModuleAnalysisPage"
import AssignmentsPage from "./pages/AssignmentsPage/AssignmentsPage"
import TermOfServicePage from "./components/TermOfServicePage/TermOfServicePage"
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage/PrivacyPolicyPage"
import AssignmentLandingPage from "./pages/AssignmentLandingPage/AssignmentLandingPage"
import LearningCenterPage from "./pages/LearningCenterPage/LearningCenterPage"
import SideBar from "./components/SideBar/SideBar"
import Home from "./tailwinds_components/app/page"
import NotFound from "./tailwinds_components/app/not-found"
import WaitlistRequest from "./pages/WaitlistRequestPage/waitlistRequest"
import SignUp from "./pages/SignupPage/signup"
import SignIn from "./pages/SigninPage/signin"
import CombinedModularSettings from "./pages/SettingsPageCombined/combined"

import { BrowserRouter as Router, Route, Routes, useNavigate, Outlet } from "react-router-dom"
import { RootStoreModel, RootStoreProvider, useStores } from "./models/root-store"
import { observer } from "mobx-react-lite"

const queryClient = new QueryClient()

function App() {
  const rootStore = RootStoreModel.create({} as any)

  return (
    <QueryClientProvider client={queryClient}>
      <RootStoreProvider value={rootStore}>
        <Router>
          <main className="flex flex-col h-screen">
            <div className="flex flex-1 overflow-hidden">
              <SideBar />
              <div className="flex flex-1 flex-col">
                <div className="flex flex-1 overflow-y-auto">
                  <Routes>
                    {/* Unauthenticated Routes */}
                    <Route path="/" element={<Home />} />
                    <Route path="/pricingpage" element={<PricingPage />} />
                    <Route path="/signin" element={<SignIn />} />
                    <Route path="/signup" element={<SignUp />} />
                    <Route path="/tos" element={<TermOfServicePage />} />
                    <Route path="/privacy" element={<PrivacyPolicyPage />} />
                    <Route path="/waitlistRequestPage" element={<WaitlistRequest />} />
                    <Route path="/notFound" element={<NotFound />} />
                    {/* Authenticated Routes */}
                    <Route element={<AuthWrapper />}>
                      <Route path="/homepage" element={<HomePage />} />
                      <Route path="/chat" element={<ChatPage />} />
                      <Route path="/context" element={<ContextPage />} />
                      <Route path="/settings" element={<CombinedModularSettings />} />
                      <Route path="/managerDashboard" element={<ManagerDashboardPage />} />
                      <Route path="/managerTeam" element={<MyTeamPage />} />
                      <Route path="/managerDev" element={<ManagerDevelopmentPage />} />
                      <Route path="/activityTeamView" element={<ActivityTeamViewPage />} />
                      <Route path="/activityCreationPage" element={<ActivityCreationPage />} />
                      <Route path="/moduleAnalysisPage" element={<ModuleAnalysisPage />} />
                      <Route path="/assignmentsPage" element={<AssignmentsPage />} />
                      <Route path="/assignmentLandingPage" element={<AssignmentLandingPage />} />
                      <Route path="/PresentationPage" element={<PresentationPage />} />
                      <Route path="/learningCenterPage" element={<LearningCenterPage />} />
                    </Route>
                  </Routes>
                </div>
              </div>
            </div>
            {/* <div className="flex">Footer</div> <-- placeholder for sticky footer in future */}
          </main>
        </Router>
      </RootStoreProvider>
    </QueryClientProvider>
  )
}

export default App

/**
 * Wrapper for all routes that require authentication. Checking `Auth.currentSession()`
 * for existing AWS session. If error is caught, user is redirected to signin. On success,
 * `authorized` is set to `true` in the AppContext. Pages can access the value like this:
 *
 * import { useAppContext } from "../../lib/appContext"
 * const { authorized } = useAppContext()
 *
 */

const LoadingScreen = () => (
  <div className="flex items-center justify-center min-h-screen w-full">
    <l-grid size="120" speed="1.4" color="#3045FF" />
  </div>
)

const AuthWrapper = observer(() => {
  const navigate = useNavigate()
  const { userStore } = useStores()

  // Loading state to track the authentication process
  const [loading, setLoading] = useState(true)

  const authenticateUser = async () => {
    try {
      if (!userStore.user) {
        const authenticated = await userStore.getUser()
        if (!authenticated) {
          navigate("/signin")
        }
      }
    } catch (error) {
      console.error("Error during authentication:", error)
      navigate("/signin") // In case of error, redirect to sign-in
    } finally {
      setLoading(false) // Stop loading after authentication is done
    }
  }

  useEffect(() => {
    authenticateUser()
  }, [])

  // Show loading screen while authenticating
  if (loading) {
    return <LoadingScreen />
  }

  // Once authenticated, render the page
  return userStore.user ? <Outlet /> : <div className="flex-1" />
})
